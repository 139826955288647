<template>
  <v-form
    ref="form"
    style="height: 100%;"
    @submit.prevent
  >
    <v-sheet
      color="grey lighten-3 d-flex flex-column"
      height="100%"
    >
      <v-row
        class="px-8 py-3 bg-ease flex-grow-0"
        no-gutters
      >
        <v-col class="step-title white--text">
          {{ $t('page.teams.add_member_title') }}
        </v-col>
      </v-row>
      <v-row
        class="my-3"
        no-gutters
        style="height: calc(100% - 57px - 24px);"
      >
        <v-col
          class="step-col px-7 white"
          cols="auto"
          style=" overflow: hidden;width: 672px; max-height: 100%;"
        >
          <v-row
            class="my-3"
            align="center"
            no-gutters
          >
            <!-- 過濾團隊 <v-col style="max-width: calc(50% - 16px);">
            <v-select
              v-model="filterTeam"
              class="teams-search"
              :items="teamOptions"
              height="32"
              outlined
              hide-details
              dense
              clearable
              placeholder="所有團隊"
            ></v-select>
          </v-col>

          <v-spacer style="max-width: 16px;"></v-spacer>

          <v-col style="max-width: calc(50% - 16px);"> -->
            <v-col>
              <v-text-field
                v-model="searchMembers"
                class="teams-search"
                outlined
                hide-details
                dense
                clearable
                prepend-inner-icon="mdi-magnify"
                :placeholder="$t('page.teams.placeholder_search_member_input')"
                @input="onInputSearchMembers"
                @click:clear="onClearSearchMembers"
              />
            </v-col>
          </v-row>

          <!-- <TeamExpansionPanels :teams="teams"></TeamExpansionPanels> -->

          <v-progress-linear
            v-if="isLoadingMembers"
            indeterminate
            color="primary"
          />
          <IssueCard
            v-else-if="error || showMembers.length === 0"
            class="mt-13"
            img-width="326"
            not-found
            :message="issueMessage"
            :error="error"
            style="height: calc(100% - 98px);"
          />
          <MemberList
            v-else
            :members="showMembers"
            outlined
            style="overflow-y: auto; height: calc(100% - 56px);"
          />
        </v-col>

        <v-col
          class="step-col white ml-3 pt-4 px-7 d-flex flex-column"
          cols="auto"
          style="width: 416px; max-height: 100%;"
        >
          <!-- <div>
            <v-row no-gutters justify="center">
              <h3 style="font-size: 1.375rem;">已加入成員</h3>
            </v-row>

            <v-divider class="mt-3 mb-4"></v-divider> -->

          <TeamJoinedMembers />
          <!-- </div> -->
        </v-col>
      </v-row>
    </v-sheet>
  </v-form>
</template>

<script>
import IssueCard from '@/components/IssueCard'
// import TeamExpansionPanels from './TeamExpansionPanels'
import TeamJoinedMembers from './TeamJoinedMembers'
import MemberList from './MemberList'

import { mapState } from 'vuex'

let timer = null
const delaySearch = 400

export default {
  name: 'TeamAdderStep2',

  components: {
    IssueCard,
    // TeamExpansionPanels,
    TeamJoinedMembers,
    MemberList
  },

  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    filterTeam: '',
    searchMembers: '',
    selected: 0,
    error: false
  }),

  computed: {
    ...mapState({
      user: state => state.user.user || {},
      formData: state => state.teams.form.formData,
      teams: state => state.teams.teams,
      members: state => state.teams.members,
      isLoadingMembers: state => state.teams.isLoadingMembers
    }),

    showMembers() {
      return this.members.filter(m => m.username !== this.user.username)
    },
    teamOptions() {
      return this.teams.map(team => ({
        text: team.name,
        value: team.id
      }))
    },
    issueMessage() {
      if (this.error) return this.$t('api_errors.data_error_please_wait')

      if (this.searchMembers) return this.$t('page.teams.no_search_result')

      return this.$t('page.teams.placeholder_search_member')
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  created() {
    if (!this.user.username) {
      this.fetchUser()
    }
  },

  methods: {
    fetchUser() {
      return this.$store.dispatch('user/fetchUser')
    },
    init() {
      this.searchMembers = ''
      this.filterTeam = ''
      this.selected = 0
      this.error = false
      this.$refs.form.resetValidation()
    },
    fetchMembers() {
      this.error = false
      this.$store
        .dispatch('teams/fetchMembersByEmail', {
          email: this.searchMembers
        })
        .catch(() => {
          this.error = true
        })
    },
    onInputSearchMembers() {
      if (!this.searchMembers) {
        return
      }
      // 啟動loading，避免timeout delay造成loading畫面慢一拍
      this.$store.dispatch('teams/setState', {
        isLoadingMembers: true
      })
      clearTimeout(timer)
      timer = setTimeout(this.fetchMembers, delaySearch)
    },
    onClearSearchMembers() {
      this.error = false
      this.$store.dispatch('teams/clearMembers')
    }
  }
}
</script>

<style lang="scss" scoped>
.step-col {
  border: solid 0.8px #ddd;
}

.v-input.v-text-field--outlined.teams-search {
  ::v-deep .v-input__slot {
    min-height: 32px;

    fieldset {
      border-color: #eee;
    }

    .v-select__selections {
      .v-select__selection {
        color: $color-primary;
      }
    }

    .v-input__prepend-inner {
      margin-top: 4px;
    }

    .v-input__append-inner {
      margin-top: 4px;
    }
  }
}
</style>
